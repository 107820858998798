import React from 'react';
import { Layout, SEO } from 'components/common';
// import { Intro, Skills, Contact, Projects } from 'components/landing';
import { Header } from 'components/theme';
import { BlogHeader } from './styles';

export const BlogHome = () => (
  <Layout>
    <SEO />
    <Header />
    {/* <Projects /> */}
    {/* <Skills /> */}
    {/* <Contact /> */}
    <BlogHeader>
      {
        // TODO: fix formatting/CSS here
        // TODO: add email list integration, using mailchimp or something idk
      }
      <p>Hello this is my blog, currently under construction</p>
    </BlogHeader>
  </Layout>
);
